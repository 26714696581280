.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customize-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.customize-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
}

.expand-container {
  overflow: hidden;
}

.expand-body {
  margin-top: 0px;
  transition: margin-top 400ms ease-in;
}

.expand-head[expand='true'] > .arrow {
  transform: scaleY(-1);
}

.expand-head[expand='true'] > .revert {
  transform: scaleY(-1);
}

.expand-head {
  height: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: #333333;
  font-weight: 700;
}

.revert {
  align-self: center;
}

.arrow {
  transition: transform 0.4s ease-in;
  align-self: center;
  border-top: 0.4em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}

.ant-checkbox.foo span.ant-checkbox-inner {
  background-color: hotpink;
  border-radius: 50%;
}